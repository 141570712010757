<!--省优秀毕业生-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">校优秀毕业生</div>
    <!--=====================================工具栏========================================-->
    <div class="btngroup">
      <el-button
        type="primary"
        size="mini"
        @click="showExport"
      >导出Excel</el-button>
      <el-button
        type="primary"
        size="mini"
        @click="getDataPageList"
      >查询</el-button>
    </div>
    <div class="sm_search">
      <div class="sm_search_group">
        <div class="sm_search_item">
          <div class="sm_search_title">届次</div>
          <el-select
            v-model="nj"
            size="mini"
            clearable
            style="display: block; width: 178px"
          >
            <el-option
              v-for="(item, index) in njlist"
              :key="index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">学院</div>
          <el-select
            v-model="bmbh"
            size="mini"
            clearable
            @change="bmchange"
            style="display: block; width: 178px"
          >
            <el-option
              v-for="(item, index) in bmlist"
              :key="index"
              :label="item.bmmc"
              :value="item.bmbh"
            ></el-option>
          </el-select>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">专业</div>
          <el-select
            v-model="zybh"
            size="mini"
            clearable
            @change="zychange"
            style="display: block; width: 178px"
          >
            <el-option
              v-for="(item, index) in zylist"
              :key="index"
              :label="item.zymc"
              :value="item.zybh"
            ></el-option>
          </el-select>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">班级</div>
          <el-select
            v-model="bjbh"
            size="small"
            clearable
            style="display: block; width: 178px"
          >
            <el-option
              v-for="(item, index) in bjlist"
              :key="index"
              :label="item.bjmc"
              :value="item.bjbh"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="sm_search_group">
        <div class="sm_search_item">
          <div class="sm_search_title">学号</div>
          <el-input
            size="mini"
            v-model="xh"
          ></el-input>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">姓名</div>
          <el-input
            size="mini"
            v-model="xm"
          ></el-input>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">状态</div>
          <el-select
            v-model="spzt"
            size="small"
            clearable
            style="display: block; width: 178px"
          >
            <el-option
              label="待辅导员审批"
              :value="0"
            ></el-option>
            <el-option
              label="辅导员审批通过"
              :value="100"
            ></el-option>
            <el-option
              label="辅导员审批不通过"
              :value="200"
            ></el-option>
            <el-option
              label="学院审批通过"
              :value="110"
            ></el-option>
            <el-option
              label="学院审批不通过"
              :value="120"
            ></el-option>
            <el-option
              label="学工审批通过"
              :value="111"
            ></el-option>
            <el-option
              label="学工审批不通过"
              :value="112"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        stripe
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column
          prop="xh"
          label="学号"
        > </el-table-column>
        <el-table-column
          prop="xm"
          label="姓名"
        > </el-table-column>
        <el-table-column
          prop="xbm"
          label="性别"
        >
          <template slot-scope="scope">
            {{ scope.row.xbm == 1 ? '男' : '女' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="bjmc"
          label="班级"
        > </el-table-column>
        <el-table-column
          prop="nj"
          label="届次"
        > </el-table-column>
        <el-table-column
          prop="zt"
          label="状态"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.spzt === 0">待辅导员审核</el-tag>
            <el-tag
              v-if="scope.row.spzt === 100"
              type="success"
            >辅导员审核通过</el-tag>
            <el-tag
              v-if="scope.row.spzt === 200"
              type="danger"
            >辅导员审核拒绝</el-tag>
            <el-tag
              v-if="scope.row.spzt === 110"
              type="success"
            >院系审核通过</el-tag>
            <el-tag
              v-if="scope.row.spzt === 120"
              type="danger"
            >院系审核拒绝</el-tag>
            <el-tag
              v-if="scope.row.spzt === 111"
              type="success"
            >学工审核通过</el-tag>
            <el-tag
              v-if="scope.row.spzt === 112"
              type="danger"
            >学工审核拒绝</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="150"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="detailInfo(scope.row.id)"
            >查看</el-button>
            <el-button
              type="text"
              size="small"
              @click="print(scope.row.id)"
            >下载审批表</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>
    <!--添加弹出框-->
    <el-dialog
      title="省优秀毕业生审批"
      :visible.sync="dialogFormVisible"
      width="70%"
      :close-on-click-modal="false"
      @close="closeDialog"
      top="5vh"
    >
      <el-scrollbar
        style="height: 700px"
        class="myscrollbar"
      >
        <el-timeline>
          <el-timeline-item
            v-for="(item, index) in forminfo.spjl"
            :key="index"
            :timestamp="dateFormat2(item.cjsj)"
            placement="top"
          >
            <el-card>
              <h4 v-if="item.spzt == 100 || item.spzt == 200">
                辅导员审批{{ item.spzt == 100 ? '通过' : '拒绝' }}
              </h4>
              <h4 v-if="item.spzt == 110 || item.spzt == 120">
                院系审批{{ item.spzt == 110 ? '通过' : '拒绝' }}
              </h4>
              <h4 v-if="item.spzt == 111 || item.spzt == 112">
                学工审批{{ item.spzt == 111 ? '通过' : '拒绝' }}
              </h4>
              <p>审批意见：{{ item.spyj }}</p>
            </el-card>
          </el-timeline-item>
        </el-timeline>
        <el-form
          :model="spinfo"
          v-if="dialogFormVisible"
          ref="formRef"
        >
          <el-card class="box-card">
            <span class="staffBmTitle">四川省普通高校优秀毕业生推荐表</span>
            <table
              border="1px"
              class="staffBmTable"
            >
              <tr>
                <td>学校名称</td>
                <td colspan="3">
                  {{ forminfo.XXMC }}
                </td>
                <td>院系专业</td>
                <td colspan="3">
                  {{ forminfo.YXZY }}
                </td>
              </tr>
              <tr>
                <td style="width: 100px">姓名</td>
                <td>
                  {{ forminfo.XM }}
                </td>
                <td style="width: 100px">性别</td>
                <td style="width: 100px">
                  {{ forminfo.XB }}
                </td>
                <td style="width: 100px">出生年月</td>
                <td>
                  {{ forminfo.CSNY }}
                </td>
                <td style="width: 100px">民族</td>
                <td>
                  {{ forminfo.MZ }}
                </td>
              </tr>
              <tr>
                <td>政治面貌</td>
                <td>
                  {{ forminfo.ZZMM }}
                </td>
                <td>学历层次</td>
                <td colspan="2">
                  {{ forminfo.CCDM }}
                </td>
                <td>职务</td>
                <td colspan="2">
                  {{ forminfo.ZW }}
                </td>
              </tr>
              <tr>
                <td>主要事迹</td>
                <td
                  colspan="7"
                  style="text-align: left"
                >
                  {{ forminfo.ZYSJ }}
                </td>
              </tr>
              <tr>
                <td>院系推荐意见</td>
                <td
                  colspan="7"
                  style="text-align: left"
                >
                  {{ forminfo.YXTJYJ }}
                </td>
              </tr>
            </table>
          </el-card>
        </el-form>
      </el-scrollbar>
    </el-dialog>
    <el-dialog
      title="导出Excel数据"
      :visible.sync="dialogExcelVisible"
    >
      <div>
        <el-checkbox
          v-model="excelForm.XM"
          label="姓名"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.XB"
          label="性别"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.MZ"
          label="民族"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.ZZMM"
          label="政治面貌"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.CSNY"
          label="出生年月"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.BMMC"
          label="院系名称"
          border
          style="width: 130px; margin-top: 10px; margin-left: 0px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.ZYMC"
          label="专业名称"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.XH"
          label="学号"
          border
          style="width: 130px; margin-top: 10px; margin-left: 10px"
        ></el-checkbox>
        <el-checkbox
          v-model="excelForm.XLCC"
          label="学历层次"
          border
          style="width: 130px; margin-top: 10px"
        ></el-checkbox>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogExcelVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="exportToExcel"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getPYXXSCXPageList,
  exportPYXXSSearchExcel,
  getPYXXSSQFormData,
  // getPYXXSPdf,
  getPdfUrl,
} from '../../api/py'
import { GetListByQX, GetZYListByQX, GetBJListByQX } from '../../api/jcxx'
import { getAuthorizeButtonColumnList, getNFList } from '../../api/system'
import { baseUrl } from '../../utils/http'
import moment from 'moment'
export default {
  data() {
    return {
      downurl: baseUrl,
      fileList: [],
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight - 100, // 实时屏幕高度
      keyword: '',
      keyValue: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      splist: [], // 审批记录
      xsinfo: {},
      bmlist: [],
      zylist: [],
      bjlist: [], // 班级列表
      njlist: '',
      nj: '',
      bmbh: '',
      zybh: '',
      bjbh: '',
      xh: '',
      xm: '',
      xn: '',
      spzt: null,
      xnList: [],
      forminfo: {
        CCDM: '',
        XXMC: '成都艺术职业大学',
        FJBH: '',
        LXDH: '',
        JTDZ: '',
        YXZY: '',
        NJ: '',
        ID: '',
        PCID: '',
        XH: '',
        XM: '',
        XB: '',
        CSNY: '',
        MZ: '',
        ZZMM: '',
        ZW: '',
        STEP: 0,
        SPZT: null,
        DRZW: '',
        ZYSJ: '',
        YXTJYJ: '',
        XJSS: null,
        ZHCP: null,
        fj: [],
      },
      spinfo: {
        SPYJ: '',
        SPZT: 1,
        ZHCPPM: '',
        XJSS: null,
      },
      dialogFormVisible: false, // 是否显示弹出框
      dialogDetailFormVisible: false, // 是否显示详情弹出框
      // 表单验证规则对象
      forminfoRules: {
        SPYJ: [{ required: true, message: '请填写审批意见', trigger: 'blur' }],
        SPZT: [{ required: true, message: '请审批', trigger: 'blur' }],
      },
      dialogExcelVisible: false,
      excelForm: {
        XM: true,
        XB: true,
        MZ: true,
        ZZMM: true,
        CSNY: true,
        BMMC: true,
        ZYMC: true,
        XH: true,
        XLCC: true,
      },
    }
  },
  created() {
    GetListByQX().then((res) => {
      if (res.code === 200) {
        this.bmlist = res.data
      }
    })
    getNFList().then((res) => {
      if (res.code === 200) {
        this.njlist = res.data
      }
    })
    this.getDataPageList()
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.forminfo = JSON.parse(JSON.stringify(this.formdata))
      })
      this.$refs.formRef.resetFields()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
      } else {
        this.keyValue = currentRow.id
      }
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      getPYXXSCXPageList({
        queryJson: JSON.stringify({
          XM: this.xm,
          XH: this.xh,
          NJ: this.nj,
          BMBH: this.bmbh,
          ZYBH: this.zybh,
          BJBH: this.bjbh,
          SPZT: this.spzt,
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }

      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    dateFormat2(value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY年MM月DD日 HH:mm')
    },
    // 时间格式化
    dateFormat3(value) {
      if (!value) {
        return ''
      }

      return moment(value).format('YYYY-MM')
    },
    // 获取专业列表
    getZY() {
      GetZYListByQX({ BMBH: this.bmbh }).then((res) => {
        if (res.code === 200) {
          this.zylist = res.data
        }
      })
    },
    // 获取班级列表
    getBJ() {
      GetBJListByQX({ ZYBH: this.zybh }).then((res) => {
        if (res.code === 200) {
          this.bjlist = res.data
        }
      })
    },
    bmchange() {
      this.zybh = ''
      this.bjbh = ''
      this.getZY()
    },
    zychange() {
      this.bjbh = ''
      this.getBJ()
    },
    showExport() {
      if (this.pagination.total === 0) {
        this.$message.error('无法导出空数据列表！')
      } else {
        this.dialogExcelVisible = true
      }
    },
    // 显示编辑弹出框
    detailInfo(id) {
      if (id === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.operation = 0
        getPYXXSSQFormData({ id: id }).then((res) => {
          if (res.code === 200) {
            this.splist = res.data.spjl
            this.forminfo.XXMC = '成都艺术职业大学'
            this.forminfo.XH = res.data.xh
            this.forminfo.XM = res.data.xm
            this.forminfo.XB = res.data.xb === '1' ? '男' : '女'
            this.forminfo.CSNY = this.dateFormat3(res.data.csny)
            this.forminfo.JTDZ = res.data.jtdz
            this.forminfo.LXDH = res.data.lxdh
            // this.forminfo.RXSJ = this.dateFormat4(res.data.rxsj)
            this.forminfo.MZ = res.data.mz
            this.forminfo.ZZMM = res.data.zzmm
            this.forminfo.YXZY = res.data.bmmc + res.data.zymc
            this.forminfo.ZW = res.data.zw
            this.forminfo.FJBH = res.data.fjbh
            this.forminfo.ZYSJ = res.data.zysj
            this.forminfo.CCDM =
              res.data.bjmc.indexOf('本') !== -1 ? '本科' : '专科'
            this.forminfo.SPZT = res.data.spzt
            this.forminfo.YXTJYJ = res.data.yxtjyj
            this.forminfo.spjl = res.data.spjl
            this.dialogFormVisible = true
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    exportToExcel() {
      var exportFiles = []
      if (this.excelForm.XM) {
        exportFiles.push({ name: 'XM', label: '姓名' })
      }
      if (this.excelForm.XB) {
        exportFiles.push({ name: 'XB', label: '性别' })
      }
      if (this.excelForm.MZ) {
        exportFiles.push({ name: 'MZ', label: '民族' })
      }
      if (this.excelForm.ZZMM) {
        exportFiles.push({ name: 'ZZMM', label: '政治面貌' })
      }
      if (this.excelForm.CSNY) {
        exportFiles.push({ name: 'CSNY', label: '出生年月' })
      }
      if (this.excelForm.BMMC) {
        exportFiles.push({ name: 'BMMC', label: '院系名称' })
      }
      if (this.excelForm.ZYMC) {
        exportFiles.push({ name: 'ZYMC', label: '专业名称' })
      }
      if (this.excelForm.XH) {
        exportFiles.push({ name: 'XH', label: '学号' })
      }

      if (this.excelForm.XLCC) {
        exportFiles.push({ name: 'XLCC', label: '学历层次' })
      }
      if (exportFiles.length === 0) {
        this.$message.error('请选择要导出的列！')
        return false
      }

      this.$message.info('数据导出中，请耐心等待！')
      this.dialogExcelVisible = false
      exportPYXXSSearchExcel({
        queryJson: JSON.stringify({
          XM: this.xm,
          XH: this.xh,
          NJ: this.nj,
          BMBH: this.bmbh,
          ZYBH: this.zybh,
          BJBH: this.bjbh,
          SPZT: this.spzt,
        }),
        fileName: '四川省普通高校优秀毕业生推荐名单',
        exportField: JSON.stringify(exportFiles),
      }).then((res) => {
        console.log(res)
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8',
        })
        // 针对于IE浏览器的处理, 因部分IE浏览器不支持createObjectURL
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, res.fileName)
        } else {
          var downloadElement = document.createElement('a')
          var href = window.URL.createObjectURL(blob) // 创建下载的链接
          downloadElement.href = href
          downloadElement.download = res.fileName // 下载后文件名
          document.body.appendChild(downloadElement)
          downloadElement.click() // 点击下载
          document.body.removeChild(downloadElement) // 下载完成移除元素
          window.URL.revokeObjectURL(href) // 释放掉blob对象
        }
      })
    },
    print(id) {
      // getPYXXSPdf({ ID: id }, '优秀毕业生申请表.pdf').then((res) => {
      //   var blob = new Blob([res.data], {
      //     type: 'application/pdf;charset=utf-8',
      //   })
      //   // 针对于IE浏览器的处理, 因部分IE浏览器不支持createObjectURL
      //   if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //     window.navigator.msSaveOrOpenBlob(blob, res.fileName)
      //   } else {
      //     var downloadElement = document.createElement('a')
      //     var href = window.URL.createObjectURL(blob) // 创建下载的链接
      //     downloadElement.href = href
      //     downloadElement.download = res.fileName // 下载后文件名
      //     document.body.appendChild(downloadElement)
      //     downloadElement.click() // 点击下载
      //     document.body.removeChild(downloadElement) // 下载完成移除元素
      //     window.URL.revokeObjectURL(href) // 释放掉blob对象
      //   }
      // })
      getPdfUrl({ ID: id }).then((res) => {
        if (res.code === 200) {
          console.log(res.data)
          window.open(this.downurl + res.data.url, '_blank')
        }
      })
    },
  },
}
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.btngroup {
  height: 28px;
  padding: 3px !important;
  width: 98%;
  margin: 2px auto;
  overflow: hidden;
  min-width: 600px;
  text-align: left;
}
.sm_search {
  padding: initial;
  border-top: 1px solid #ddd !important;
  background-color: #f3f3f3;
  width: 100%;
}
.sm_search_group {
  display: flex;
  padding: 5px 0 5px 0;
}
.sm_search_item {
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 14px;
}
.sm_search_title {
  min-width: 65px;
  text-align: right;
  margin-right: 5px;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
  text-align: center;
  font-weight: 700;
}
>>> .el-table .el-table__body tr.el-table__row.current-row td,
.el-table__body tr.current-row > td,
.el-table__body tr.hover-row.current-row > td,
.el-table__body tr.hover-row.el-table__row.current-row > td,
.el-table__body tr.hover-row.el-table__row > td,
.el-table__body tr.hover-row > td {
  background-color: #409eff !important;
  color: white;
}
.DivTable {
  width: 99%;
  margin: 0 auto;
  margin-top: 10px;
}
table {
  display: table;
  border-collapse: collapse;
  box-sizing: border-box;
  text-indent: initial;
  white-space: normal;
  line-height: normal;
  font-weight: normal;
  font-size: medium;
  font-style: normal;
  color: -internal-quirk-inherit;
  text-align: start;
  border: 1px solid #bdc8d3;
  font-variant: normal;
}
.DivTable tr {
  min-height: 32px;
  border: 0;
}
.DivTable .EdtTitleBack {
  background-color: #fbfafa;
  text-align: right;
  height: 32px;
  overflow: hidden;
}
.DivTable td {
  min-height: 28px;
  border: 0;
  border: 1px solid #bdc8d3;
}
.DivTable .EdtBack {
  background-color: #fff;
  text-align: left;
  height: 32px;
  overflow: hidden;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
.staffBmTitle {
  margin-top: 1px;
  font-weight: 700;
  text-align: center;
  display: block;
  color: #000;
  font-size: 24px;
  margin-bottom: 10px;
}
.inputnoborder >>> .el-input__inner {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom-color: #000;
}
.staffBmTable {
  width: 100%;
  font-size: 14px;
  text-align: center;
  border-color: #000;
}
.staffBmTable tr td {
  padding: 10px;
}
.cell-class {
  border-color: #000 !important;
}
</style>
